
import Vue from "vue"
import { Route } from "vue-router"
import { UnionItemDefinition } from "@/types/responses"
export default Vue.extend({
	name: "Extensions",
	watch: {
		$route(to: Route, from: Route) {
			console.error(to, from)
		},
	},
	computed: {
		extensionItems(): UnionItemDefinition["subItems"] {
			const modules: UnionItemDefinition[] = this.$vStore.state.nav.modules ? this.$vStore.state.nav.modules["items"] : []
			const extensionsModule = modules.find((module) => {
				if (module.isExtension && module.subItems.length > 0) return module
			})
			return extensionsModule ? extensionsModule["subItems"] : []
		},
	},
	methods: {
		bgImage(bgImage: string): void {
			return require(`@/assets/bg/${bgImage}`)
		},
	},
})
